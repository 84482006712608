import React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import ParallaxImage from "../components/parallaxImage";
import Layout from "../components/layout";
import SafeZone from "../components/safeZone";
import HeadingRow from "../components/headingRow";
import SEO from "../components/seo";

const CaseStudy = ({
  data: {
    prismicCaseStudy: {
      data: {
        body: { raw: body },
        cover_image: { url, alt },
        title: {
          raw: [{ text: title }],
        },
      },
    },
  },
}) => (
  <>
    <SEO title={title} />
    <Layout>
      <ParallaxImage url={url} alt={alt} height="fullScreen">
        <HeadingRow>
          <h1 style={{ textShadow: "var(--text-shadow)" }}>{title}</h1>
        </HeadingRow>
      </ParallaxImage>
      <SafeZone>
        <div style={{ fontSize: "1.4em", lineHeight: "2" }}>
          {RichText.render(body)}
        </div>
      </SafeZone>
    </Layout>
  </>
);

export default CaseStudy;

CaseStudy.propTypes = {
  data: PropTypes.object,
};

CaseStudy.defaultProps = {
  data: {},
};

export const query = graphql`
  query CaseStudyBySlug($uid: String!) {
    prismicCaseStudy(uid: { eq: $uid }) {
      id
      data {
        body {
          raw {
            text
            type
            spans {
              start
              end
              type
            }
          }
        }
        cover_image {
          url
          alt
        }
        title {
          raw {
            type
            text
          }
        }
      }
    }
  }
`;
